import { css_beautify } from "js-beautify";
import { flow } from "lodash";
import { createMinioCssUrl } from "@/utils/helpers";
import type { CommonStyles } from "@/types/PopupItemType";
import type { CSSBeautifyOptions } from "js-beautify";
import type { CSSProperties } from "vue";

/**
 * Копирует значения данных из объекта стилей, отфильтровывая неопределенные или недопустимые свойства.
 *
 * @param source - Объект, содержащий данные стилей.
 * @returns Отфильтрованный объект стилей.
 */
export const takeValidStyles = (source: Partial<CommonStyles>) => {
  const result: Partial<CommonStyles> = {};

  if (source.Left !== undefined && source.Left !== null) {
    result.Left = source.Left;
  }

  if (source.Top !== undefined && source.Top !== null) {
    result.Top = source.Top;
  }

  if (source.PositionType) {
    result.PositionType = source.PositionType;
  }

  if (source.ButtonPosition) {
    result.ButtonPosition = source.ButtonPosition;
  }

  if (
    source.Width !== undefined &&
    source.Width !== null &&
    source.Width !== 0
  ) {
    result.Width = source.Width;
  }

  if (
    source.Height !== undefined &&
    source.Height !== null &&
    source.Height !== 0
  ) {
    result.Height = source.Height;
  }

  if (
    source.PaddingHorizontal !== undefined &&
    source.PaddingHorizontal !== null
  ) {
    result.PaddingHorizontal = source.PaddingHorizontal;
  }

  if (source.PaddingVertical !== undefined && source.PaddingVertical !== null) {
    result.PaddingVertical = source.PaddingVertical;
  }

  if (source.TextSize) {
    result.TextSize = source.TextSize;
  }

  if (source.TextColor) {
    result.TextColor = source.TextColor;
  }

  if (source.BackgroundColor) {
    result.BackgroundColor = source.BackgroundColor;
  }

  if (source.BackgroundImage) {
    result.BackgroundImage = source.BackgroundImage;
  }

  if (source.BackgroundSize) {
    result.BackgroundSize = source.BackgroundSize;
  }

  if (source.BorderColor) {
    result.BorderColor = source.BorderColor;
  }

  if (source.BorderStyle) {
    result.BorderStyle = source.BorderStyle;
  }

  if (source.BorderWidth !== undefined && source.BorderWidth !== null) {
    result.BorderWidth = source.BorderWidth;
  }

  if (source.BorderRadius !== undefined && source.BorderRadius !== null) {
    result.BorderRadius = source.BorderRadius;
  }

  if (source.HorizontalAlign) {
    result.HorizontalAlign = source.HorizontalAlign;
  }

  if (source.VerticalAlign) {
    result.VerticalAlign = source.VerticalAlign;
  }

  if (source.AnimationType) {
    result.AnimationType = source.AnimationType;
  }

  if (source.AnimationDuration) {
    result.AnimationDuration = source.AnimationDuration;
  }

  return result;
};

/**
 * Форматирует объект стилей, добавляя нужные единицы измерения CSS.
 *
 * @param source - Объект стилей для форматирования.
 * @returns Объект стилей с добавленными единицами измерения.
 */
export const applyCssUnits = (source: Partial<CommonStyles>) => {
  const styles: CSSProperties = {};

  if (source.Left !== undefined && source.Left !== null) {
    styles.left = source.Left + "px";
  }

  if (source.Top !== undefined && source.Top !== null) {
    styles.top = source.Top + "px";
  }

  if (
    source.Width !== undefined &&
    source.Width !== null &&
    source.Width !== 0
  ) {
    styles.width = source.Width + "px";
  }

  if (
    source.Height !== undefined &&
    source.Height !== null &&
    source.Height !== 0
  ) {
    styles.height = source.Height + "px";
  }

  if (source.PaddingVertical !== undefined && source.PaddingVertical !== null) {
    styles.padding = source.PaddingVertical + "px";
  }

  if (
    source.PaddingHorizontal !== undefined &&
    source.PaddingHorizontal !== null
  ) {
    styles.padding += ` ${source.PaddingHorizontal}px`;
  } else {
    styles.padding += " 0";
  }

  if (source.TextSize) {
    styles.fontSize = source.TextSize + "px";
  }

  if (source.TextColor) {
    styles.color = source.TextColor;
  }

  if (source.BackgroundColor) {
    styles.backgroundColor = source.BackgroundColor;
  }

  if (source.BackgroundImage?.Url) {
    styles.backgroundImage = createMinioCssUrl(source.BackgroundImage.Url);
  }

  if (source.BackgroundSize) {
    styles.backgroundSize = source.BackgroundSize;
  }

  if (source.BorderWidth) {
    styles.borderWidth = source.BorderWidth + "px";
  }

  if (source.BorderStyle) {
    styles.borderStyle = source.BorderStyle;
  }

  if (source.BorderColor) {
    styles.borderColor = source.BorderColor;
  }

  if (source.BorderRadius !== undefined && source.BorderRadius !== null) {
    styles.borderRadius = source.BorderRadius + "px";
  }

  return styles;
};

/**
 * Добавляет фоновое изображение к объекту стилей, вместе с параметрами размера и повторения.
 *
 * @param styles - Базовые стили для применения фонового изображения.
 * @param url - URL фонового изображения.
 * @returns Объект стилей с фоновым изображением.
 */
export const applyBackgroundImage = (styles: CSSProperties, url?: string) => {
  if (!url) {
    return styles;
  }

  const result: CSSProperties = {
    ...styles,
    backgroundImage: createMinioCssUrl(url),
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
  };

  return result;
};

/**
 * Удаляет стили ширины и высоты, если они равны "0px", чтобы избежать нулевых размеров.
 *
 * @param styles - Объект стилей, из которого нужно удалить нулевые размеры.
 * @returns Объект стилей без нулевых размеров.
 */
export const removeZeroSizeStyles = (styles: CSSProperties) => {
  if (styles.width === "0px" || styles.height === "0px") {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { width, height, ...result } = styles;

    return result;
  }

  return styles;
};

/**
 * Подготавливает стили с использованием функции flow из lodash для последовательного применения функций.
 *
 * @param styles - Исходный объект стилей.
 * @returns Подготовленный и отформатированный объект стилей.
 */
export const prepareStyles = (styles: Partial<CommonStyles>) =>
  flow(
    takeValidStyles,
    applyCssUnits,
    withFlexStyles,
    removeZeroSizeStyles
  )(styles);

/**
 * Расширяет объект стилей, добавляя свойства flexbox для центровки содержимого.
 *
 * @param styles - Объект стилей для расширения.
 * @returns Объект стилей с flexbox свойствами.
 */
export const withFlexStyles = (styles: Partial<CSSProperties>) => ({
  ...styles,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

/**
 * Обновляет или добавляет CSS-свойство в строке стилей CSS для указанного класса.
 *
 * Ищет указанное CSS-свойство в строке и обновляет его значение, если оно существует
 * в блоке стилей указанного класса. Если свойство не найдено в этом блоке, добавляет его.
 *
 * @param cssString - Исходная строка CSS.
 * @param className - Имя класса, в который нужно внести изменения.
 * @param property - Имя свойства для обновления/добавления.
 * @param value - Значение, которое нужно установить для свойства.
 * @returns Обновленная строка CSS.
 */
/**
 * Обновляет или добавляет CSS-свойство в строке стилей CSS для указанного класса.
 *
 * Ищет указанное CSS-свойство в строке и обновляет его значение, если оно существует
 * в блоке стилей указанного класса. Если свойство не найдено в этом блоке, добавляет его.
 *
 * @param cssString - Исходная строка CSS.
 * @param className - Имя класса, в который нужно внести изменения.
 * @param property - Имя свойства для обновления/добавления.
 * @param value - Значение, которое нужно установить для свойства.
 * @returns Обновленная строка CSS.
 */
export const updateCssProperty = (
  className: string,
  cssString: string,
  property: string,
  value: string
): string => {
  const classRegex = new RegExp(`(\\.${className}\\s*{)([\\s\\S]*?)(})`, "g");
  const newProperty = `${property}: ${value};`;

  return cssString.replace(classRegex, (_, start, content, end) => {
    const propertyRegex = new RegExp(`(${property}:\\s*[^;]*;)`);
    let updatedContent = content.trim(); // Удаляем все начальные и конечные переносы строк

    if (propertyRegex.test(updatedContent)) {
      updatedContent = updatedContent.replace(propertyRegex, newProperty);
    } else {
      // Добавляем новое свойство с корректным отступом
      updatedContent = `${updatedContent}\n  ${newProperty}`.trim();
    }

    return `${start}\n${updatedContent}\n${end}`; // Добавляем переносы строк только для начала и конца блока
  });
};

export const toKebabCase = (str: string) => {
  return str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
};

export const formatCss = (css: string, _config?: CSSBeautifyOptions) => {
  const config = { indent_size: 2, space_in_empty_paren: true, ..._config };
  return css_beautify(css, config);
};

export const replaceCssClass = (inputCss: string, newClassName: string) => {
  // Регулярное выражение для поиска всех селекторов классов
  const classRegex = /\.(?!\d)[\w-]+(?=[\s{:,])/g;
  const uniqueClasses = new Set<string>();
  let match;

  // Извлекаем все уникальные классы-селекторы
  while ((match = classRegex.exec(inputCss)) !== null) {
    uniqueClasses.add(match[0]); // Включаем точку
  }

  // Заменяем все уникальные классы на новый
  uniqueClasses.forEach((oldClass) => {
    const oldClassRegex = new RegExp(`\\${oldClass}(?=\\b|[_:])`, "g");
    inputCss = inputCss.replace(oldClassRegex, `.${newClassName}`);
  });

  return inputCss;
};
