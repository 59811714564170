import { cloneDeep } from "lodash";
import type { Ref } from "vue";

export function generateId(): string {
  return (Math.random() * 1e4).toString(16);
}

export const createBaseUrl = (url: string) =>
  `${process.env.VUE_APP_BASE_API_URL}/${
    url.startsWith("/") ? url.slice(1) : url
  }`;

export const createMinioUrl = (url: string) =>
  `${process.env.VUE_APP_MINIO_URL}/${url}`;

export const createMinioCssUrl = (url: string) => `url(${createMinioUrl(url)})`;

type RecursiveObject = {
  [key: string]: RecursiveObject | unknown;
};

export function deepSyncProperties(
  source: RecursiveObject,
  target: RecursiveObject
): void {
  // Копируем все ключи из source в target
  Object.keys(source).forEach((key) => {
    const sourceValue = source[key];

    if (
      sourceValue !== null &&
      typeof sourceValue === "object" &&
      !Array.isArray(sourceValue) &&
      !(sourceValue instanceof Date)
    ) {
      if (
        !(key in target) ||
        target[key] === null ||
        typeof target[key] !== "object" ||
        Array.isArray(target[key]) ||
        target[key] instanceof Date
      ) {
        target[key] = {};
      }
      // Рекурсивный вызов для копирования вложенных объектов
      deepSyncProperties(
        sourceValue as RecursiveObject,
        target[key] as RecursiveObject
      );
    } else {
      // Если свойство не является объектом или является null, просто копируем его значение.
      target[key] = cloneDeep(sourceValue);
    }
  });

  // Удаляем ключи из target, которые отсутствуют в source для полной синхронизации
  Object.keys(target).forEach((key) => {
    if (!(key in source)) {
      delete target[key];
    }
  });
}

/**
 * Создаёт модель с преобразованием значения.
 *
 * @param refObject - Реактивный объект, содержащий свойство.
 * @param key - Ключ свойства, которое будет читаться и изменяться.
 * @param transformFn - Функция для преобразования значения в set.
 * @returns Объект с геттерами и сеттерами для computed.
 */
export const createTransformedModel = (
  refObject: Ref,
  key: string,
  transformFn: (value: string) => string
) => ({
  get() {
    return refObject.value[key];
  },
  set(value: string) {
    refObject.value[key] = transformFn(value);
  },
});

// Специфичная функция для цветов
export const createColorModel = (refObject: Ref, key: string) =>
  createTransformedModel(refObject, key, (value) => `#${value}`);

// Специфичная функция для замены символов
export const createReplaceModel = (
  refObject: Ref,
  key: string,
  replaceRegex: RegExp
) =>
  createTransformedModel(refObject, key, (value) =>
    value.replace(replaceRegex, "")
  );

export const removeByKey = (
  array: Record<string, unknown>[],
  key: string,
  value: unknown
): Record<string, unknown> | undefined => {
  const index = array.findIndex((item) => item[key] === value);
  if (index !== -1) {
    return array.splice(index, 1)[0];
  }
};

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);
