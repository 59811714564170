const styles = ["border-radius: 2px", "font-size: 16px", "font-weight: 500"];

const info = ["color: #6666ff", "background-color: #efefff"];
const error = ["color: #ff3333", "background-color: #ffefef"];

export const logInfo = (message: string) => {
  console.info(`%c${message}`, [...styles, ...info].join(";"));
};

export const logError = (...messages: unknown[]) => {
  console.error(`%c${messages.join(", ")}`, [...styles, ...error].join(";"));
};
