import { ref } from "vue";
import { defineStore } from "pinia";
import projectService from "@/services/ProjectService";
import { validateProject } from "@/store/validateProject";
import { serializeToFormData } from "@/utils";
import type { ProjectItemType, ProjectModelType } from "@/types/ProjectType";

export const useProjectStore = defineStore("project", () => {
  const projectsList = ref<ProjectItemType[]>([]);
  const isLoading = ref<boolean>(true);
  const isNotFound = ref<boolean>(false);

  const fetchProjectsList = async (params: Record<string, unknown> = {}) => {
    projectsList.value = [];

    const [error, data] = await projectService.getList(params);

    if (error) return error;

    const records = data.data.records;

    if (records?.length) {
      projectsList.value = records;
      isNotFound.value = false;
    } else {
      projectsList.value = [];
      isNotFound.value = true;
    }

    isLoading.value = false;
  };

  const addProject = async (project: ProjectModelType) => {
    const { isValid, errors } = validateProject(project);

    if (!isValid) return errors;

    const formData = new FormData();
    serializeToFormData(formData, project);

    const [error, data] = await projectService.add(formData);

    if (error) return error;

    if (data.data) {
      projectsList.value.unshift(data.data);
      isNotFound.value = false;
    }
  };

  const editProject = async (project: ProjectModelType) => {
    const { isValid, errors } = validateProject(project);

    if (!isValid) return errors;

    const formData = new FormData();
    serializeToFormData(formData, project);

    const id = project.ID as string;
    const [error, data] = await projectService.update(id, formData);

    if (error) return error;

    const targetProject = projectsList.value.find((item) => item.ID === id);
    const updatedProject = data.data;

    if (typeof targetProject !== "undefined") {
      targetProject.Name = updatedProject.Name;
      targetProject.UpdatedAt = updatedProject.UpdatedAt;
      targetProject.Urls = updatedProject.Urls;
      targetProject.IsActive = updatedProject.IsActive;
    }
  };

  const deleteProject = async (project: ProjectItemType) => {
    const id = project.ID as string;

    const [error] = await projectService.remove(id);

    if (error) return error;

    projectsList.value.splice(projectsList.value.indexOf(project), 1);
    isNotFound.value = projectsList.value.length === 0;

    return error;
  };

  const toggleProject = async (project: ProjectItemType) => {
    const id = project.ID;
    const isActive = !project.IsActive;

    const [error] = await projectService.toggle(id, isActive);

    if (error) return error;

    project.IsActive = isActive;
  };

  const deleteProjectUrl = async (model: ProjectModelType, index: number) => {
    if (typeof model.Urls[index].ID === "undefined") {
      model.Urls.splice(index, 1);
      return;
    }

    const id = model.Urls[index].ID as string;

    const [error] = await projectService.removeUrl(id);

    if (error) return error;

    model.Urls.splice(index, 1);

    const project = projectsList.value?.find((item) => item.ID === model.ID);

    if (project && project.Urls) {
      project.Urls.splice(index, 1);
    }
  };

  return {
    projectsList,
    isLoading,
    isNotFound,
    fetchProjectsList,
    addProject,
    editProject,
    deleteProject,
    toggleProject,
    deleteProjectUrl,
  };
});
